import React from 'react';
import { Link } from 'react-router-dom';
import { AppBar, Button, Toolbar } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import shiosa1t from './../assets/images/icon.png';
import X from './../assets/images/X.png';
import bsky from './../assets/images/bsky.png';
import GitHub from './../assets/images/GitHub.png';

export const Home = () => {
  return (
    <div className='App'>
        <header className='App-header'>
            <AppBar position='static' elevation={0} sx={{ backgroundColor: "#282b30"}}>
                <Toolbar>
                    <Typography variant='h5' component='div' sx={{ flexGrow: 1 }}>
                    </Typography>
                    <Button color='inherit' component={Link} to='/history' style={{textTransform: 'none'}}>
                        <Typography sx={{ fontSize: '2vh' }}>
                            History
                        </Typography>
                    </Button>
                    <Box sx={{ width: '2vh'}} />
                    <Button color='inherit' component={Link} to='/blog' style={{textTransform: 'none'}}>
                        <Typography sx={{ fontSize: '2vh' }}>
                            Blog
                        </Typography>
                    </Button>
                </Toolbar>
            </AppBar>
        </header>
        <body className='App-body'>
            <Avatar
                src={shiosa1t}
                alt='logo'
                sx={{ width: '20vh', height: '20vh' }}
            />
            <p className='Handle-name'>
                shiosa1t
            </p>
            {/* <p className='Real-name'>
            Shio Kudo
            </p> */}
            <Stack
                direction='row'
                spacing={'5vh'}
            >
            <a href='https://twitter.com/shiosa1t'>
                <img src={X} className='Icon-link' alt='X'></img>
            </a>
            <a href='https://bsky.app/profile/shiosa1t.dev'>
                <img src={bsky} className='Icon-link' alt='bsky'></img>
            </a>
            <a href='https://github.com/shiosa1t'>
                <img src={GitHub} className='Icon-link' alt='GitHub'></img>
            </a>
            {/* <a href='https://zenn.dev/shio_salt'>
                <img src={Zenn} className='Icon-link'></img>
            </a> */}
            </Stack>
        </body>
        <footer className='App-footer'>
        &copy; 2024 shiosa1t
        </footer>
    </div>
  );
}
