import React from 'react'
import { Link } from 'react-router-dom'
import { AppBar, Button, Toolbar } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import shiosa1t from './../assets/images/icon.png';

export const Blog = () => {
  return (
    <div className='App'>
      <header className='App-header'>
        <AppBar position='static' elevation={0} sx={{ backgroundColor: "#282b30"}}>
            <Toolbar>
                <Button color='inherit' component={Link} to='/' style={{textTransform: 'none'}}>
                    <Avatar
                        src={shiosa1t}
                        alt='logo'
                        sx={{ width: '5vh', height: '5vh' }}
                    />
                    <Box sx={{ width: '2vh'}} />
                    <Typography sx={{ fontSize: '2vh' }}>
                        shiosa1t
                    </Typography>
                </Button>
                <Typography variant='h5' component='div' sx={{ flexGrow: 1 }}>
                </Typography>
                <Button color='inherit' component={Link} to='/history' style={{textTransform: 'none'}}>
                    <Typography sx={{ fontSize: '2vh' }}>
                        History
                    </Typography>
                </Button>
                <Box sx={{ width: '2vh'}} />
                <Button color='inherit' component={Link} to='/blog' style={{textTransform: 'none'}}>
                    <Typography sx={{ fontSize: '2vh' }}>
                        Blog
                    </Typography>
                </Button>
            </Toolbar>
        </AppBar>
      </header>
      <body className='App-body'>
        <List>
          <ListItem alignItems="flex-start">
            <Box
            sx={{
              width: '50vw',
              height: '10vh',
              borderRadius: '2vh',
              marginTop: '-20vh',
              // bgcolor: 'primary.main',
            }}
            >
              <Typography sx={{ fontSize: '3vmax' }}>
                Articles / 記事
              </Typography>
            </Box>
          </ListItem>
          <ListItemButton href='/blog/new-years-resolution-2024'
            sx={{
              borderRadius: '2vh',
              bgcolor: '#424549',
            }}>
            <Box>
              <Typography sx={{ fontSize: '2vmax', margin: '2vmax' }}>
                2024年の抱負 
              </Typography>
              <Typography sx={{ fontSize: '1vmax', margin: '2vmax', color: '#999999' }}>
                2024-01-04
              </Typography>
            </Box>
          </ListItemButton>
        </List>
      </body>
      <footer className='App-footer'>
        &copy; 2024 shiosa1t
      </footer>
    </div>
  )
}