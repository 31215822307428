import React from 'react'
import { Link } from 'react-router-dom'
import { AppBar, Button, Toolbar } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import shiosa1t from './../assets/images/icon.png';

export const History = () => {
  return (
    <div className='App'>
      <header className='App-header'>
        <AppBar position='static' elevation={0} sx={{ backgroundColor: "#282b30"}}>
            <Toolbar>
                <Button color='inherit' component={Link} to='/' style={{textTransform: 'none'}}>
                    <Avatar
                        src={shiosa1t}
                        alt='logo'
                        sx={{ width: '5vh', height: '5vh' }}
                    />
                    <Box sx={{ width: '2vh' }} />
                    <Typography sx={{ fontSize: '2vh' }}>
                        shiosa1t
                    </Typography>
                </Button>
                <Typography variant='h5' component='div' sx={{ flexGrow: 1 }}>
                </Typography>
                <Button color='inherit' component={Link} to='/history' style={{textTransform: 'none'}}>
                    <Typography sx={{ fontSize: '2vh' }}>
                        History
                    </Typography>
                </Button>
                <Box sx={{ width: '2vh'}} />
                <Button color='inherit' component={Link} to='/blog' style={{textTransform: 'none'}}>
                    <Typography sx={{ fontSize: '2vh' }}>
                        Blog
                    </Typography>
                </Button>
            </Toolbar>
        </AppBar>
      </header>
      <body className='App-body'>
      <Box
            sx={{
              textAlign: 'left',
            }}
        >
          <Typography sx={{ fontSize: '1vmax' }}>
            仮<br />
            <br />
            2020: SecHack365 学習駆動コース/今岡ゼミ 優秀修了<br />
            2021: セキュリティ・キャンプ 全国大会 修了<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;: パソコン甲子園 本選出場<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;: STAGE:0 競技プログラミング 高校生大会 敢闘賞<br />
            2022: セキュリティ・キャンプ 全国大会 チューター<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;: SECCON CTF 2022 Domestic Finals 3位<br />
            2023: セキュリティ・ミニキャンプ in 新潟 講師<br />
            2024: 未踏IT人材発掘・育成事業 クリエータ<br />
            <br />
            2020/01 - : Defios株式会社 アルバイト<br />
            2023/12 - : 株式会社Flatt Security 長期インターン セキュリティエンジニア<br />
          </Typography>
        </Box>
      </body>
      <footer className='App-footer'>
        &copy; 2024 shiosa1t
      </footer>
    </div>
  )
}
