import React from 'react'
import { Link } from 'react-router-dom'
import { AppBar, Button, Toolbar } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import shiosa1t from './../../assets/images/icon.png';

export const NewYearsResolution2024 = () => {
  return (
    <div className='App'>
      <header className='App-header'>
        <AppBar position='static' elevation={0} sx={{ backgroundColor: "#282b30"}}>
            <Toolbar>
                <Button color='inherit' component={Link} to='/' style={{textTransform: 'none'}}>
                    <Avatar
                        src={shiosa1t}
                        alt='logo'
                        sx={{ width: '5vh', height: '5vh' }}
                    />
                    <Box sx={{ width: '2vh'}} />
                    <Typography sx={{ fontSize: '2vh' }}>
                        shiosa1t
                    </Typography>
                </Button>
                <Typography variant='h5' component='div' sx={{ flexGrow: 1 }}>
                </Typography>
                <Button color='inherit' component={Link} to='/history' style={{textTransform: 'none'}}>
                    <Typography sx={{ fontSize: '2vh' }}>
                        History
                    </Typography>
                </Button>
                <Box sx={{ width: '2vh'}} />
                <Button color='inherit' component={Link} to='/blog' style={{textTransform: 'none'}}>
                    <Typography sx={{ fontSize: '2vh' }}>
                        Blog
                    </Typography>
                </Button>
            </Toolbar>
        </AppBar>
      </header>
      <body className='App-blog' style={{ minHeight: '270vh'}}>
        <Box
            sx={{
              width: '800px',
              height: '10vh',
              borderRadius: '2vh',
              marginTop: '-240vh',
              textAlign: 'left',
            }}
        >
            <Typography sx={{ fontSize: '3vmax' }}>
                2024年の抱負
            </Typography>
            <Box sx={{ height: '2vh'}} />
            <Typography sx={{ fontSize: '1vmax', color: '#999999' }}>
                2024/01/04
            </Typography>
            <Box sx={{ height: '5vh'}} />
            <Typography sx={{ fontSize: '1vmax' }}>
                あけましておめでとうございます！<br></br>
                タイトルの通り、今年も抱負を書きます。<br></br>
            </Typography>
            <Box sx={{ height: '5vh'}} />
            <Typography sx={{ fontSize: '2vmax' }}>
                去年の抱負の振り返り
            </Typography>
            <hr></hr>
            <Box sx={{ height: '2vh'}} />
            <Typography sx={{ fontSize: '1vmax' }}>
                2023年の抱負は<a href='https://zenn.dev/shio_salt/articles/a76892d2bcb750'>Zennの記事</a>から
                見ることができます。<br></br>
                達成できたものは[v]、できなかったものは[x]にしてまとめます。<br></br><br></br>
                [v] 未踏に応募<br></br>
                [x] セキュリティ・ネクストキャンプに応募<br></br>
                [x] 応用情報技術者試験といった資格の取得<br></br>
                [x] 論文の執筆<br></br>
                [v] プログラミング言語Rustの学習<br></br>
                [x] VALORANTでレディアントに到達<br></br>
                [x] VALORANT分析ツールのリリース<br></br>
                [v] 日報の更新<br></br>
                [v] Googleカレンダーの利用<br></br>
                [x] 自動車免許の取得<br></br><br></br>
                10個中4個しか達成できませんでした。<br></br>
                理由としては抱負に書いた以外のことが忙しく、できなかったことが多いです。<br></br>
                VALORANTに至ってはプレイ時間が70%以上減少していました。<br></br>
                そもそも全てゲームのプレイ時間が大きく減少しました。<br></br>
                しかしながら、何もしていなかったわけではありません。<br></br>
                セキュリティ・ミニキャンプの講師やインターンなど新しいことができました。<br></br>
                詳しくは、Zennに投稿した<a href='https://zenn.dev/shio_salt/articles/7fa24deb59d8cd'>2023年の振り返り</a>をご覧ください。<br></br>
            </Typography>
            <Box sx={{ height: '5vh'}} />
            <Typography sx={{ fontSize: '2vmax' }}>
                2024年の抱負
            </Typography>
            <hr></hr>
            <Box sx={{ height: '2vh'}} />
            <Typography sx={{ fontSize: '1vmax' }}>
                - 未踏やセキュリティ・ネクストキャンプ等に応募<br></br>
                - 基本情報技術者や応用情報技術者等の資格の取得<br></br>
                - CTFへの参加<br></br>
                - 記事の執筆<br></br>
                - 論文の執筆<br></br>
                - 自動車免許の取得<br></br>
                - ゲームで何かを成し遂げる<br></br><br></br>
            未踏やセキュリティ・ネクストキャンプには引き続き挑戦します。<br></br>
            また、資格の取得も同様に挑戦したいと思います。<br></br>
            2023年は体調不良で受験できていないので、体調管理にも気をつけたいです。<br></br>
            CTFには参加していましたが、もっと回数を増やしていきたいです。<br></br>
            記事の執筆も同様に2023年は増えたものの少ないので、増やしていきたいです。<br></br>
            どちらも毎月1回以上を目標にします。<br></br>
            論文の執筆は、できるか分かりませんがやりたいです。<br></br>
            そして、学会に提出してみたいです。<br></br>
            自動車免許は、忙しすぎて何もできませんでした。<br></br>
            勉強自体はオンデマンドでもできるみたいなので、余裕があればやりたいです。<br></br>
            ゲームで何かを成し遂げるについては、大会への出場などをしたいです。<br></br>
            かなりゲームをやっていますが、結果がないので何か成し遂げたいです。<br></br>
            最後に、2024年の抱負は抽象的にしました。<br></br>
            1人の人間なので、できることには限界があります。<br></br>
            また、多くのことを同時にやろうとすると質が低下してしまいます。<br></br>
            2023年の抱負は、具体的かつ多かったためにほとんどできませんでした。<br></br>
            そこで、2024年の抱負は少し曖昧にしました。<br></br>
            もちろん頑張ることには頑張りますが、程良いペースでやっていきたいです。<br></br>
            また、物事を計画的に行いたいです。<br></br>
            そろそろ締切駆動コースを完全に修了したい。<br></br>
            </Typography>
            <Box sx={{ height: '5vh'}} />
            <Typography sx={{ fontSize: '2vmax' }}>
                おわりに
            </Typography>
            <hr></hr>
            <Box sx={{ height: '2vh'}} />
            <Typography sx={{ fontSize: '1vmax' }}>
                以上が2024年の抱負です。<br></br>
                Cloudflareへの移管や技術的な問題で、公開が遅れてしまいました。<br></br>
                去年の記事では1/1に投稿する予定としていましたが、1/4になってしまいました。<br></br>
                今年の抱負に加え、shiosa1t.devの整備も頑張りたいです。<br></br>
                2023年も周囲の方々のおかげで色々なことができました。<br></br>
                ありがとうございました。<br></br>
                2024年もよろしくお願いします。<br></br>
            </Typography>
        </Box>
      </body>
      <footer className='App-footer'>
        &copy; 2024 shiosa1t
      </footer>
    </div>
  )
}