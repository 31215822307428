import { Route, Routes } from "react-router-dom";
import { Home } from "./../pages/HomePage";
import { History } from "./../pages/HistoryPage";
import { Blog } from "./../pages/BlogPage";
import { NewYearsResolution2024 } from "./../pages/blogs/new-years-resolution-2024";

// import nprogress from 'nprogress'
// import 'nprogress/nprogress.css'

// nprogress.configure({ showSpinner: false, speed: 400, minimum: 0.25 })

export const Router = () => {
    return (
        <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/history" element={<History />} />
            <Route path="/blog" element={<Blog />} />
            <Route path="/blog/new-years-resolution-2024" element={<NewYearsResolution2024 />} />
        </Routes>
    );
}
